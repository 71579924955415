import {addLetterModal} from './tacho_shared'
import {AjaxSync} from "../shared/ajax_utilities";
import {dateConvert, isIsoWeek} from "../shared/text_utilities";
import moment from 'moment';

class TachoDriverActivityModals {
    #$table

    constructor($table) {
        this.#$table = $table
        this.addLetterModal = addLetterModal.bind(this)

        this.configureModals();
        this.#configureHandlers();
    }

    configureModals() {
        this.$buttons = this.#$table.find('.btn-remodal')
        this.$buttons.filter('.btn-remodal').off('click')

        this.$buttons.filter('.btn-remodal').click((e) => {
            e.preventDefault()
            let $el = $(e.target)
            if ($el[0].tagName !== 'a') {
                $el = $el.parents('a')
            }
            $el.addClass('remodal-active')

            const modalType = $el.data('micromodalTrigger')

            this._configureCommentModal(modalType, $el)
            MicroModal.show(modalType, {
                onClose: (modal, activeElement, event) => this._handleCloseCommentModal(modal, activeElement, event, $el)
            })
        })
    }

    _configureCommentModal(modalType, $button) {
        const $modal = $('div#' + modalType)
        let modalTypeName

        switch ($button.attr('data-type')) {
            case 'tco_drv_anl':
                modalTypeName = 'Infringement'
                break
            case 'tco_drv_act_day':
                modalTypeName = 'Day'
                break
            case 'tco_drv_act':
                modalTypeName = 'Activity'
                break
            default:
                modalTypeName = $button.attr('data-type')
                break
        }


        $modal.find('h1 span').text(modalTypeName)

        if (modalType === 'tacho_add_comment') {

            // clear any existing input
            $modal.find('#text').val('')

            let key = $button.parents('tr').attr('id')
            const type = key.substring(0, 1)

            if (type === 'I') {
                $modal.find('#tbl').val('tco_drv_anl')
                key = key.substring(1)
            } else if (type === 'C') {
                $modal.find('#tbl').val('tco_drv_act_day')
                key = key.substring(1)
            } else {
                $modal.find('#tbl').val('tco_drv_act')
            }

            $modal.find('#event_key').val(key)
        }

        if (modalType === 'tacho_edit_comment') {
            $modal.find('#text').val($button.parent().prev('td').text().trim())
            $modal.find('#comment_id').val($button.data('comment-id'))
        }

        if (modalType === 'tacho_delete_comment') {
            $modal.find('#comment_id').val($button.data('comment-id'))
        }
    }

    _handleCloseCommentModal(modal, activeElement, event, $sourceElement) {
        const modalType = modal.id,
            $modal = $(modal),
            triggerElement = event.target

        if (triggerElement.tagName !== 'BUTTON' || !$(triggerElement).hasClass('remodal-confirm')) {
            return
        }

        const data = $modal.find('input,select,textarea').serializeArray()

        let targetRowID

        let targetURL

        if (modalType === 'tacho_add_comment') {
            targetURL = app.CACHE.URL_AJAX + 'tacho_drv/get/add_comment'
            targetRowID = $modal.find('#event_key').val()
            switch ($modal.find('#tbl').val()) {
                case 'tco_drv_anl':
                    targetRowID = 'I' + targetRowID
                    break
                case 'tco_drv_act_day':
                    targetRowID = 'C' + targetRowID
                    break
            }
        }

        if (modalType === 'tacho_edit_comment') {
            targetURL = app.CACHE.URL_AJAX + 'tacho_drv/get/edit_comment'
            targetRowID = $sourceElement
                .first()
                .closest('tr')
                .attr('id')
        }

        if (modalType === 'tacho_delete_comment') {
            targetURL = app.CACHE.URL_AJAX + 'tacho_drv/get/delete_comment'
            targetRowID = $sourceElement
                .first()
                .closest('tr')
                .prev()
                .attr('id')
        }

        AjaxSync({
            url: targetURL,
            data: data,
            method: 'POST'
        }, { done: (res) => window.location.reload() });
    }

    #configureHandlers() {

        const $table = this.#$table;

        app.DOM.form.find('#toggle_cumulative').on('click', function () {

            // change button state
            $(this).find('span.instance').toggleClass('hidden');
            $(this).find('span.cumulative').toggleClass('hidden');
            // change table view
            $table.find('span.instance').toggleClass('hidden');
            $table.find('span.cumulative').toggleClass('hidden');
        });

        app.DOM.form.find('#toggle_tails').on('click', function () {

            // change button state
            $(this).toggleClass('button-blue button-red');
            $(this).find('span.show_tails').toggleClass('hidden');
            $(this).find('span.hide_tails').toggleClass('hidden');

            // change table display
            $table.find('span.show_tails').toggleClass('hidden');
            $table.find('span.hide_tails').toggleClass('hidden');
            $table.find('tr.tails').toggleClass('hidden');
        });

        const that = this;

        app.DOM.form.find('#create_infringement_letter').on('click', function (e) {

            e.preventDefault();
            const $el = $(e.currentTarget);
            const letterType = $el.attr('data-letter-type');
            const range = ( letterType !== 'range' ) ? dateConvert(app.URI[3]) : $el.attr('data-letter-range').split('/');

            that.addLetterModal(
                'activity',
                that.#$table,
                app.URI[2],
                letterType,
                range,
                app.OPTIONS.driver.name,
                app.OPTIONS.driver.email
            );
        });

        app.DOM.content.find('#btn-preview-letter').on('click', function (e) {
            e.preventDefault();
            if(isIsoWeek(app.URI[3])) {
                const splitDate = app.URI[3].split('W');
                const date = moment().year(splitDate[0]).week(splitDate[1]);
                window.location = `${app.CACHE.URL_ADMIN}tco_drv_let_preview/tacho_driver_id:${app.URI[2]}/type:range/${date.day(1).format('YYYY-MM-DD')}/${date.add('1', 'weeks').day(0).format('YYYY-MM-DD')}`;
                return;
            }

            window.location = `${app.CACHE.URL_ADMIN}tco_drv_let_preview/tacho_driver_id:${app.URI[2]}/type:day/range:${dateConvert(app.URI[3])}`;
        });
    }
}

$(() => {
    new TachoDriverActivityModals($('#tacho_driver_breakdown'))
});